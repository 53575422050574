<template>
  <div class="login-wrapper">
    <div class="login-content">
      <div class="login-logo">
        <logo />
      </div>

      <form v-show="!message" action="" @submit.prevent="submit">
        <div class="auth_block">
          <div class="login-form">
            <form-group label="Email">
              <input type="text" autocomplete="email" v-model="form.email" class="form-control" :class="{valid:form.email}">
            </form-group>
          </div>
        </div>

        <button type="submit" class="default-button login-button" style="margin-top: 20px;">
          Submit
        </button>

        <div v-show="errors" class="login-button default-button error" style="margin-top: 20px; display: block;">
          {{ getFirstError() }}
        </div>
      </form>

      <div v-show="message" class="success-block">
        <div class="success-block-message">
          {{ message }}
        </div>
        <router-link to="/" class="login-button default-button">
          Go back to login
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import FormGroup from '../libs/forms/FormGroup'
import Logo from "@/libs/Logo";

export default {
  name: 'ResetPassword',
  components: {
    Logo,
    FormGroup
  },
  data () {
    return {
      form: {
        email: ''
      },

      errors: null,
      message: ''
    }
  },
  computed: {
    formData () {
      return {
        email: this.form.email
      }
    }
  },
  methods: {
    submit () {
      this.$sdk.auth.requestResetPassword(this.formData).then(response => {
        if (Object.prototype.hasOwnProperty.call(response.data, 'success')) {
          this.errors = null
          this.message = response.data.message
        } else {
          this.errors = response.data
        }
      }).catch((error) => {
        if (error.response) {
          this.errors = [[error.response.data.message]]
        } else {
          this.errors = [[error.message]]
        }
      })
    },

    /**
     * Display first message in to summary block
     */
    getFirstError () {
      for (const key in this.errors) {
        const value = this.errors[key][0]
        if (value.length > 0) {
          return value
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .success-block {
    &-message {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      line-height: 1.2em;
      margin-bottom: 30px;
    }
  }
</style>
