<template>
  <div class="form-group" :class="{'has-error': hasMessage}">
    <slot name="label-block">
      <label :class="labelClass" v-if="label">{{ label }}</label>
    </slot>
    <slot name="default" />
    <slot name="help-block">
      <div :class="errorClass" v-if="hasMessage">
        <span>{{ message }}</span>
      </div>
    </slot>
    <slot name="hint-block">
      <transition name="fade" :duration="200">
        <div :class="hintClass" v-if="hasHint">
          {{ hint }}
        </div>
      </transition>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Boolean],
      default: ''
    },
    labelClass: {
      type: String,
      default: 'control-label'
    },

    hint: {
      type: String,
      default: null
    },
    hintClass: {
      type: String,
      default: 'hint-block'
    },

    error: {
      type: [Array, String],
      default: ''
    },
    errorClass: {
      type: String,
      default: 'help-block'
    }
  },
  computed: {
    message: function () {
      if (Array.isArray(this.error)) {
        return this.error[0]
      } else {
        return this.error
      }
    },
    hasMessage () {
      return typeof this.message === 'string' && this.message.length > 0
    },
    hasHint () {
      return typeof this.hint === 'string' && this.hint.length > 0
    }
  }
}
</script>
